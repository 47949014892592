import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import EhrUserLayout from '../views/layouts/EhrUserLayout.vue';

Vue.use(VueRouter);

export const authorizedRoutes = [
  {
    path: '/cryo',
    name: 'Cryo Dashboard',
    component: () => import('../views/Cryo/Index.vue'),
    meta: {
      icon: 'mdi-view-dashboard'
    },
    children: [
      {
        path: '',
        component: () => import('../views/Cryo/General.vue')
      },
      {
        path: 'invoice/:id',
        component: () => import('../views/Cryo/General.vue')
      },
      {
        path: ':category',
        component: () => import('../views/Cryo/Category.vue')
      }
    ]
  },
  {
    path: '/activities',
    name: 'Activities',
    component: () => import('../views/Cryo/Index.vue'),
    meta: {
      icon: 'mdi-sync'
    },
    children: [
      {
        path: '',
        component: () => import('../views/Cryo/Activities.vue')
      }
    ]
  },
  {
    path: '/invoices',
    name: 'Invoices',
    component: () => import('../views/Cryo/Index.vue'),
    meta: {
      icon: 'mdi-credit-card'
    },
    children: [
      {
        path: '',
        component: () => import('../views/Cryo/Invoices.vue')
      }
    ]
  },
  {
    path: '/payment-history',
    name: 'Payment History',
    component: () => import('../views/Cryo/Index.vue'),
    meta: {
      icon: 'mdi-history'
    },
    children: [
      {
        path: '',
        component: () => import('../views/Cryo/PaymentHistory.vue')
      }
    ]
  },
  {
    path: '/set-password/:token',
    name: 'SetPassword',
    component: () => import('../views/Auth/SetPassword.vue'),
    meta: {
      hide: 'mdi-calendar-check'
    }
  },
  {
    path: '/' || '/checklist',
    name: 'Treatment Plan',
    component: () => import('../views/TreatmentPlan'),
    meta: {
      icon: 'mdi-calendar-check',
      svg: 'treatment_plan.svg'
    }
  },
  {
    path: '/medications',
    name: 'All Medications',
    component: () => import('../views/TreatmentPlan/Medications')
  },
  {
    path: '/appointments',
    name: 'All Appointments',
    component: () => import('../views/TreatmentPlan/Appointments')
  },
  {
    path: '/messages',
    name: 'Messages',
    component: () => import('../views/Message/Index.vue'),
    meta: {
      icon: 'mdi-forum',
      svg: 'messages.svg'
    },
    children: [
      {
        path: '',
        name: 'Messages',
        component: () => import('../views/Message/SupportChat.vue')
      },
      {
        name: 'New Message',
        path: 'new',
        component: () => import('../views/Message/NewMessage.vue')
      },
      {
        name: 'Messages',
        path: 'conversations',
        component: () => import('../views/Message/MessagesList.vue')
      },
      {
        name: 'New Message',
        path: 'reply',
        component: () => import('../views/Message/Reply.vue')
      }
    ]
  },
  {
    path: '/labs',
    name: 'Labs',
    component: () => import('../views/Labs'),
    meta: {
      icon: 'mdi-flask',
      svg: 'labs.svg'
    },
    children: [
      {
        path: '',
        name: 'Labs',
        component: () => import('../views/Labs/Labs.vue')
      },
      {
        path: 'blood-test',
        name: 'Blood test results',
        component: () => import('../views/Labs/List.vue')
      },
      {
        path: 'embryology',
        name: 'Embryology results',
        component: () => import('../views/Labs/List.vue')
      },
      {
        path: 'semen-analysis',
        name: 'Semen analysis',
        component: () => import('../views/Labs/List.vue')
      },
      {
        path: 'ultrasound-result',
        name: 'Ultrasounds',
        component: () => import('../views/Labs/ListNew.vue')
      },
      {
        path: 'detail/:id',
        name: 'Ultrasound',
        component: () => import('../views/Labs/Detail.vue')
      }
    ]
  },
  {
    path: '/documents',
    name: 'Documents',
    component: () => import('../views/Documents/Index.vue'),
    meta: {
      icon: 'mdi-file-document',
      svg: 'documents.svg'
    },
    children: [
      {
        path: '',
        name: 'Documents',
        component: () => import('../views/Documents/Category.vue')
      },
      {
        name: 'Documents',
        path: 'list/:category',
        component: () => import('../views/Documents/Documents.vue')
      },
      {
        name: 'Documents',
        path: 'detail/:id',
        component: () => import('../views/Documents/Details.vue')
      }
    ]
  },
  {
    path: '/patient-forms',
    name: 'Patient Forms',
    component: () => import('../views/PatientForms'),
    meta: {
      icon: 'mdi-clipboard-outline',
      svg: 'patient_forms.svg'
    }
  },
  {
    path: '/finance',
    name: 'Finance',
    component: () => import('../views/Finance.vue'),
    meta: {
      icon: 'mdi-currency-usd',
      svg: 'payments.svg'
    }
  },
  {
    path: '/my-partner',
    name: 'My Partner',
    component: () => import('../views/Partner/Index.vue'),
    meta: {
      icon: 'mdi-account-plus',
      svg: 'my_partner.svg'
    },
    children: [
      {
        path: '',
        component: () => import('../views/Partner/Listing.vue')
      },
      {
        path: 'new',
        component: () => import('../views/Partner/New.vue')
      },
      {
        path: 'accept/:id',
        component: () => import('../views/Partner/Accept.vue')
      },
      {
        path: 'unlink/:id',
        component: () => import('../views/Partner/Unlink.vue')
      },
      {
        path: 'invitationType',
        component: () => import('../views/Partner/InvitationType.vue')
      },
      {
        path: 'showMyCode',
        component: () => import('../views/Partner/ShowMyCode.vue')
      },
      {
        path: 'enterPartnerCode',
        component: () => import('../views/Partner/EnterPartnerCode.vue')
      }
    ]
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('../views/About.vue'),
    meta: {
      icon: 'mdi-cog',
      svg: 'my_account.svg'
    }
  },
  // {
  //   path: '/clinic-info',
  //   name: 'Clinic Info',
  //   component: () => import('../views/ClinicInfo'),
  //   meta: {
  //     icon: 'mdi-phone',
  //     svg: 'clinic_info.svg'
  //   }
  // }
  {
    path: '/directions',
    name: 'Directions',
    component: () => import('../views/Directions.vue'),
    meta: {
      icon: 'mdi-map-marker',
      svg: 'directions.svg'
    }
  },
  {
    path: '/callus',
    name: 'Call Us',
    component: () => import('../views/CallUs.vue'),
    meta: {
      icon: 'mdi-phone',
      svg: 'call_us.svg'
    }
  }
];

export const unauthorizedRoutes = [
  {
    path: 'login/:token',
    name: 'loginWithToken',
    component: () => import('../views/Auth/LoginWithToken.vue')
  },
  {
    path: 'magic-link/:id',
    name: 'MagicLink',
    component: () => import('../views/Auth/MagicLink.vue')
  },
  {
    path: '',
    name: 'Checking',
    component: () => import('../views/Auth/CheckingAuth.vue')
  },
  {
    path: 'login',
    name: 'Login',
    component: () => import('../views/Auth/LoginPage.vue')
  },
  {
    path: 'logout',
    name: 'Logout',
    component: () => import('../views/Auth/Logout.vue')
  },
  {
    path: '/app/forms',
    name: 'Patient Forms App',
    component: () => import('../views/Forms.vue'),
    meta: {
      icon: 'mdi-form'
    }
  },
  {
    path: '/app/download',
    name: 'Download Document',
    component: () => import('../views/Download.vue'),
    meta: {
      icon: 'mdi-form'
    }
  },
  {
    path: '/app/invoice/:pid/:id/:token',
    name: 'Invoice Detail',
    component: () => import('../views/Invoice_Detail.vue'),
    meta: {
      icon: 'mdi-form'
    }
  },
  {
    path: '/app/invoice/transaction',
    name: 'Invoice_Transaction',
    component: () => import('../views/Invoice_Transaction.vue'),
    meta: {
      icon: 'mdi-form'
    }
  },
  {
    path: '/app/magic-link-route/:id/:route/:token',
    name: 'MagicLinkRoute',
    component: () => import('../views/Auth/MagicLink.vue')
  },
  {
    path: '/app/mobile',
    name: 'Mobile',
    component: () => import('../views/Auth/LinkApps.vue')
  }
];

const authorizedEmployeeRoutes = [
  {
    path: '/messages/',
    component: () => import('../views/Employee.vue')
  },
  {
    path: '/messages/:conversationId',
    component: () => import('../views/Employee.vue')
  }
];

const routes = [
  {
    path: '/auth',
    component: () => import('../views/Auth/AuthContainer.vue'),
    children: unauthorizedRoutes
  },
  {
    path: '/',
    component: () => import('../views/Home.vue'),
    children: authorizedRoutes
  },
  {
    path: '/messages',
    component: EhrUserLayout,
    children: authorizedEmployeeRoutes
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if (to.fullPath.startsWith('/app')) {
    next();
  } else if (to.matched.length <= 0) {
    next({ path: '/' });
  } else if (
    !to.fullPath.startsWith('/auth') &&
    !store.getters['auth/loggedIn']
  ) {
    next({ path: '/auth', query: { redirect: to.path } });
  } else {
    next();
  }
});

export default router;
