import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { VueMaskDirective, VueMaskFilter } from 'v-mask';
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
import Modal from './plugins/modal';
import Topbar from './plugins/topbar';
import Popover from 'vue-js-popover';

const components = {
  Btn: () => import('./components/global/Btn'),
  Notify: () => import('./components/global/Notify'),
  TextArea: () => import('./components/global/TextArea'),
  TextField: () => import('./components/global/TextField')
};

Object.keys(components).forEach((key) => {
  Vue.component(key, components[key]);
});

Vue.config.productionTip = false;
Vue.directive('mask', VueMaskDirective);
Vue.filter('VMask', VueMaskFilter);
Vue.use(TiptapVuetifyPlugin, {
  vuetify,
  iconsGroup: 'mdi'
});
Vue.use(Topbar);
Vue.use(Modal);
Vue.use(Popover);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
